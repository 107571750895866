import {
  disableUspDiensten,
  mainHeader,
  miContactForm,
  toggleDataVisibility,
} from "./styling";

try {
  mainHeader();
} catch (error) {
  throw Error(error);
}

try {
  miContactForm();
} catch (error) {
  throw Error(error);
}

try {
  toggleDataVisibility();
} catch (error) {
  throw Error(error);
}

try {
  disableUspDiensten();
} catch (error) {
  throw Error(error);
}
