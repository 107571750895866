import $ from "jquery";

export const disableUspDiensten = () => {
  const element =
    ".elementor-widget-usp-werkzaamheden .elementor-widget-container";
  const videoElement = ".section2-usp--video-image";

  const $element = $(element);
  const $videoElement = $(videoElement);

  const init = () => {
    $(document).ready(function () {
      if ($element.length) {
        exec();
      }
    });
  };
  const exec = () => {
    if (!$element.children().length) {
      $element.closest(".js-usp-diensten").hide();
      $videoElement.css({ maxWidth: 780 });
    }
  };
  init();
};
