import $ from "jquery";

export const toggleDataVisibility = () => {
  const target = "[data-visibility]";
  const container = ".e-con-inner";

  const $target = $(target);

  const init = () => {
    $(document).ready(function () {
      if ($target.length) {
        exec();
      }
    });
  };

  const exec = () => {
    $target.each(function () {
      const $this = $(this);
      const attr = $this.attr("data-visibility");
      if (attr === "0" || attr.toLowerCase() === "none") {
        $this.fadeOut(250);
      }
      if (attr === "1") {
        $this.fadeIn(250);
      }
    });

    setTimeout(() => {
      const $container = $target.closest(container);
      $container.each(function () {
        const $el = $(this).children();
        let isChildrenHidden = true;

        $el.each(function () {
          const isHidden =
            $(this).attr("style")?.includes("display: none") ?? false;
          if (!isHidden && isChildrenHidden) {
            isChildrenHidden = false;
          }
        });

        if (isChildrenHidden) {
          $(this).hide();
        }
      });
    }, 300);
  };

  init();
};
