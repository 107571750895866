import $ from "jquery";

export const miContactForm = () => {
  const target = {
    parent: ".mi-form-contact",
    button: ".e-form__buttons",
    form: ".elementor-form",
  };

  const $parent = $(target.parent);

  const init = () => {
    $(document).ready(function () {
      if ($parent.length) {
        exec();
      }
    });
  };
  const exec = () => {
    const $formBtn = $parent.find(target.button);
    $parent.find(target.button).remove();
    $(target.form).append($formBtn);
  };
  init();
};
