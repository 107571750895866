import $ from "jquery";

export const mainHeader = () => {
	const target = {
		container: ".mi-main-header",
		topContainer: ".mi-header-top",
		heroBanner: ".hero-banner",
	};

	const init = () => {
		$(document).ready(function () {
			exec_onscroll();
		});
	};

	const exec_onscroll = () => {
		let $targetEl = $(target.container);
		let $heroBanner = $(target.heroBanner);
		let $topContainer = $(target.topContainer);
		let maxContainerHeight = $heroBanner.outerHeight();

		let bolScroll = false;

		$(window).on("orientationchange", function () {
			$targetEl = $(target.container);
			$heroBanner = $(target.heroBanner);
			$topContainer = $(target.topContainer);
			maxContainerHeight = $heroBanner.innerHeight();
		});

		$(window).on("scroll", function () {
			const $this = $(this);
			if (bolScroll) return;

			if ($this.scrollTop() > maxContainerHeight) {
				$targetEl.addClass("opaque-bg");
				bolScroll = true;
				$topContainer.slideUp({
					complete: function () {
						bolScroll = false;
					},
				});
			} else {
				$targetEl.removeClass("opaque-bg");
				bolScroll = true;
				$topContainer.slideDown({
					complete: function () {
						bolScroll = false;
					},
				});
			}
		});
	};

	init();
};
